:root { 
    --bg-color: #fff;
    --text-color: #000;
    --border-color: #CCC;
    --border-width: 1px;
    --border-style: solid;
    --font-size: 15px;

    /* Common Form Elements */
    --button-color: rgb(0, 129, 129);
    --button-text-color: white;
    --button-hover-color: rgb(0, 190, 190);

    --link-color: #225555;
    --link-hover-color: var(--button-color);

    --border: var(--border-width) var(--border-style) var(--border-color);
    --body-border: var(--border-width) var(--border-style) #transparent;
    --buttons-border: var(--border-color) 1px solid;

    --input-border-color: #D7D7D7;
    --input-color: #555;
    --input-background-color: #CCCCCC66;
    --h1: 1.5em;
    --header-weight: normal;
    --h1-weight: normal;

    --paid-color: #CCFFCC77;
    --paid-border-color: #53905355;

    --overdue-color: #FFEEEE;
    --overdue-border-color: #90535355;
    --overdue-text-color: #905353;

    --due-soon-color:#53909022;
    --due-soon-border-color: #53909055;

    --disabled-button-text-color: #FFFFFF77;
    --spinner: white 3px solid;
    --spinner-alt-color: black;

    --error-color: #C00;
}

html {
    width: 100% !important;
    margin: 0;
    display: block;
    margin:0 !important;
    background-color:var(--bg-color);
}

a { 
    color: var(--link-color);
    cursor: pointer;
}

a:hover { 
    color: var(--link-hover-color);
}

.app  {
    border: var(--border);
    border-radius: 24px;
    width: 400px;
    margin: 0 auto;
    padding: 32px;
    text-align: center;
    display: block;
    margin-top: 48px;
}

body {
    /* font-family: Tahoma, Arial, Helvetica, sans-serif; */
    font-family: system-ui,
        -apple-system,
        BlinkMacSystemFont,
        Segoe UI,
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        Fira Sans,
        Droid Sans,
        Helvetica Neue,
        sans-serif;
    font-size: var(--font-size);
    display: block;
    overflow: auto !important;
}

table {
    border-collapse: collapse;
}

input,
select,
table td,
table th {
    font-size: var(--font-size);
}

h1 { 
    font-size: var(--h1);
    font-weight: var(--h1-weight, var(--header-weight));
    margin: 0;
    padding: 12px 0;
}

#root { 
    display: block;
}

#main { 
    max-width: 800px;
    margin: 0 auto;
    border-left: var(--body-border);
    border-right: var(--body-border);
}

.invoice-items {
    width: 100%;
    display: block;
}

button.form-button { 
    font-weight: normal !important;
    transition-property: background-color;
    transition-duration: 200ms;
    transition-timing-function: linear;
}

#payment-form.loading { 
    opacity: 0.35;
}

.invoice-payment-screen { 
    font-size: 1.25em;
}

body.dark { 

}

body.light { 

}

.form .third>div.minority.exp {
    margin-right: 24px !important;
}

/* Put these in pi-packages/.../forms.css */

.form input,
.form select,
.form textarea {
    box-sizing: border-box;
}

label.form-group,
.form-group>label,
.form-group>*>label {
    display: block;
    padding-bottom: 6px;
    font-size: var(--font-size);
}

.form-group.third {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    width: 100% !important;
    gap: 0;
}

.form-group.third > .minority { 
    width: 25% !important;
}

.form-group.multi>.third>input,
.form-group.multi > .third>select { 
    width: 100% !important;
}

.form-group.multi>.third.exp {
    margin-right: 12px;
    width: 33%;
}

.invoice-message { 
    font-size: var(--font-size);
    margin-top: 24px;
}

.invoice-message:empty { 
    display: none;
}

table > thead > tr > th { 
    text-align: left;
}

table>thead>tr>th:last-child,
table>tbody>tr>td:last-child { 
    text-align: right;
}

table.x-large { 
    width: 100%;
}

table.x-large tr th,
table.x-large tr td { 
    border-bottom: var(--border-color) 1px solid;
    padding: 8px 0;
}

table tr.total td { 
    font-weight: bold;
    font-size: 1.25em;
    text-align: right;
}

table tr.subtotal.payment > td {
    color: #070;
}

table tr.subtotal > td:first-child,
table tr.total td:first-child { 

    text-align: right;
    padding-right: 16px;
}

.invoice-payment-body { 
    padding-bottom: 128px;
}

.invoice-buttons { 
    padding-top: 56px;
    text-align: center;
}

.invoice-payment-form > .loading-area,
.invoice-payment-form > .multi-loading-area,
.invoice-items > .loading-area { 
    margin-bottom: 12px;
}

.invoice-payment-form>.loading-area:first-child { 
    margin-top: 56px;
}

.expectation-status { 
    padding: 12px 0;
    margin-bottom: 12px;
    border-radius: 12px;
}

.expectation-status.paid { 
    background-color: var(--paid-color);
    border: var(--paid-border-color) 1px solid;
    padding: 12px 12px;
}

.expectation-status.overdue {
    background-color: var(--overdue-color);
    color: var(--overdue-text-color);
    border: var(--overdue-border-color) 1px solid;
    padding: 12px 12px;
}

.expectation-status.due-soon {
    background-color: var(--due-soon-color);
    border: var(--due-soon-border-color) 1px solid;
    padding: 12px 12px;
}

.payment-form.credit-card-form { 
    padding-top: 24px;
}

.form-button { 
    overflow: hidden;
}

.form-button>div>span:last-child { 
    min-height: 30px !important;
}

h1 .header-downloading { 
    min-width: 48px;
}

h2 .header-downloading {
    min-width: 32px;
}

h3 .header-downloading {
    min-width: 24px;
}

h1 a.download,
h2 a.download,
h3 a.download {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;    
    font-size: 13px;
    padding: 6px 12px;
    border: var(--link-color) 1px solid;
    border-radius: 6px;
    opacity: 0.75;
    margin-left: 24px;
}

a.download:hover { 
    opacity: 1.0;
}

.flex { 
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

button.spinning {
    color: var(--disabled-button-text-color) !important;
}

.spinning .spinner {
    animation: infinite-rotation 1s linear infinite;
}

.form-error:empty { 
    display: none;
}

.not-found { 
    padding-top: 32px;
}

.general.form-error {
    background-color: var(--overdue-color);
    padding: 16px !important;
    font-size: var(--font-size);
    border-radius: 8px;
}

.payment-form { 
    margin-top: 32px;
}

.form-group.multi {
    align-items: flex-start !important;
}

.payment-tabs { 
    display: flex;
    border-bottom: var(--border-color) 1px dashed;
    padding-bottom: 12px;
    margin-bottom: 24px;
}

.invoice-company-logo { 
    max-width: 128px;
    max-height: 128px;
}

.invoicee {

}

.invoice-parties { 
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 0;
}

.invoice-parties > div { 
    width: 50%;
}

.invoice-parties .invoicer { 
    text-align: right;
}

.invoice-parties > div > div {
    display: flex;
    gap: 12px;
    font-size: var(--font-size);
    padding: 6px 0;
    margin: 6px 0;
    justify-content: space-between;
    border-bottom: var(--border);
}

.invoice-parties>div.invoicee>div {
    justify-content: space-between;
}

.invoice-parties>div.invoicer>div {
    justify-content: flex-end;
}

.invoice-parties>div.invoicer {
    text-align: right;
}

.invoice-parties>div>div:last-child { 
    padding-top: 0;
    border-bottom: none;
}

.invoice-parties>div>div>* {
    display: inline-block;
}

.invoice-parties>div>div>strong {
    width: 90px;
}

.is-mobile {
    display: none;
}

div.is-not-mobile {
    display: block;
}

span.is-not-mobile { 
    display: inline-block;
}

.invoice-parties>.invoicer { 
    text-align: right;
    width: 50%;
}

.invoice-parties>.invoicer>div { 
    text-align: right;
    width: 100%;
}

.no-border {
    border: none !important;
}

.no-bottom-padding {
    padding-bottom: 0 !important;
}

.to-value { 
    display: inline-block;
}

.invoice-paid { 
    text-align: center;
    padding-top: 12px;
    padding-bottom: 64px;
}

.attachments-panel { 
    border: #E0E0E0 1px solid;
    background-color: #FAFAFA;
    border-radius: 12px;
    padding: 8px 0;
    font-size: var(--font-size);
    margin-top: 24px;
}

.attachments-panel:empty {
    display: none;
}

.attachments-panel > div.attachments-panel-title { 
    font-weight: bold;
    padding: 1px;
    margin: 0 0 4px 8px;
}

.attachments-panel > a { 
    display: inline-block;
    padding: 4px 8px;
    border: var(--border);
    border-radius: 6px;
    margin: 5px;
    text-decoration: none;
    cursor: pointer;
    font-size: var(--font-size);
}

.attachments-panel>a:hover { 
    background-color:var(--due-soon-color);
}