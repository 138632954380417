:root { 
    --loading-area-primary: #EEE;
    --loading-area-secondary: #CCC;
    --spinner-color-primary: #333;
    --spinner-color-secondary: transparent;
}

.loading-area { 
    display: block;
    border-radius: 4px;
    font-size: var(--loading-font-size, inherit);
    background-color: yellow;
    min-width: 8px;
    /* transition-property: background-color;
    transition-duration: 200ms;
    transition-timing-function: ease-in-out; */
    animation-name: loading-area;
    animation-duration: 1200ms;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

.spinning-loader { 
    display: inline-block;
    padding: 0;
}

.spinning-area{
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    max-width: 256px;
    max-height: 256px;
}

.spinning-area > .spinner {
    display: inline-block;
    border-radius: 4px;
    font-size: 1px;
    padding: 0;
    margin: 0;
    border-width: 4px;
    border-style: solid;
    border-color: var(--spinner-color-primary);
    border-top-color: var(--spinner-color-secondary);
    border-radius: 100%;
    min-width: 8px;
    min-height: 8px;

    max-width: 256px;
    max-height: 256px;
    /* transition-property: background-color;
    transition-duration: 200ms;
    transition-timing-function: ease-in-out; */
    animation-name: spinning-frames;
    animation-duration: 300ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.multi-loading-area {
    display: flex;
    flex-wrap: nowrap;
    gap: 12px;
    justify-content: space-between;
    align-items: center;
}

.multi-loading-area > .loading-area {
    width: 100%;
}

@keyframes spinning-frames { 
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);        
    }
}

@keyframes loading-area {
    0% {
        background-color: var(--loading-area-primary);
    }
    50% {
        background-color: var(--loading-area-secondary);
    }
    100% {
        background-color: var(--loading-area-primary);
    }
}
