@media screen and (max-width: 991px) {
    #main {
        max-width: unset !important;
        width: 100%;
        display: block;
        padding: 12px !important;
        box-sizing: border-box;
    }

    .company-line { 
        display: block;
        margin-bottom: 24px;
        font-size: 0.75em;
    }

    .company-line>strong { 
        display: block;
    }

    .company-line > span {
        display: inline-block;
        padding-right: 8px;
    }

    .company-line>*:last-child { 
        padding-right: 0;
    }

    #mobile-logo { 
        text-align: center;
        margin: 0 auto 0 auto;
        display: block;
    }

    #mobile-logo > img { 
        max-width: 128px;
        max-height: 128px;
    }

    .invoice-parties>div { 
        width: 100%;
    }

    .invoice-parties { 
        display: block;
    }

    .invoice-parties>div>div { 
        width: 100%;
    }

    .invoice-parties>.invoicer { 
        width: 100%;
        display: none;
    }

    .to-value { 
        width: calc(100% - 90px);
        display: inline-block;
    }

    .is-mobile { 
        display: block;
    }
    
    .is-not-mobile {
        display: none;
    }
    .payment-form {
        width: 100% !important;
    }

    .payment-form .form-group>select,
    .payment-form .form-group>input[type="number"],
    .payment-form .form-group>input[type="text"] { 
        width: unset !important;
        font-size: 1.2em !important;
    }

    .form-group.multi > .third>input,
    .form-group.multi > .third>select { 
        font-size: 1.1em;
    }

    .form-group.multi>.third:last-child { 
        display: block;
    }

    .form .form-group.multi>div.majority.cvv>input { 
        width: 100% !important;
    }

    table th { 
        font-size: 0.75em;
    }

    table td { 
        font-size: 0.75em;
        align-items: flex-start;
    }

    table td:first-child {
        min-width: 120px !important;
        width: 25%;
    }

    table.x-large {
        margin: 0;
        box-sizing: border-box !important;
        overflow: hidden;
    }

    .invoice-buttons {
        padding: 12px;
        box-sizing: border-box;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: var(--bg-color);
        border-top: var(--buttons-border, var(--border));
    }

    .invoice-buttons button {
        width: 80%;
        text-align: center;
        box-sizing: content-box;
        padding-top: 24px !important;
        padding-bottom: 24px !important;
    }

    .third.exp+.third>.form-error,
    .exp>.form-error {
        min-height: 60px;
    }

}